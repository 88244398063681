import React from "react"
import ReviewFormModule from "../Forms/review-form"
import ModalTiny from "react-bootstrap/Modal"
import "./ReviewForm.scss"

import { inViewOptions, containerVariants, fadeInFromBottom, fadeInFromLeft, fadeInFromTop } from '../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { navigate } from "gatsby"
import { isMobileOnly } from "react-device-detect";

const ReviewForm = () => {
  const [show, setShow] = React.useState(true)

  const ModuleClose = () => {
    setShow(false)
    navigate('/')
  }
  return (
    <div>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            className="about-section guide-details overflow-hidden"
            ref={ref}
            initial="hidden"
            animate={"visible"}
            variants={fadeInFromTop}
          >
            <ModalTiny
              size="lg"
              show={show}
              onHide={ModuleClose}
              className="modal-form review_form_wrapper"
            >
              <ModalTiny.Header closeButton className="contact-close-btn review-header">
                <ModalTiny.Title className="w-100 heading_content ">
                  {isMobileOnly ? 
                    <h2 className="text-center mb-0">We're sorry you didn't feel like you could give us 5 stars. We always aim to meet our usual high standards.</h2> 
                    :
                    <>
                      <h2 className="text-center mb-0">
                        We're sorry you didn't feel like you could give us 5 stars
                      </h2>
                      <h3>We always aim to meet our usual high standards.</h3>
                    </>
                  }
                  <p>
                    Please tell us more below, we will look into your
                    transaction and get back to you as soon as possible{" "}
                  </p>
                </ModalTiny.Title>
              </ModalTiny.Header>
              <ModalTiny.Body className="event-section">
                <ReviewFormModule />
              </ModalTiny.Body>
            </ModalTiny>
          </motion.div>
        )}
      </InView>
    </div>
  )
}

export default ReviewForm

import React from 'react';
import ReviewPage from '../components/ReviewForm/ReviewForm'

const ReviewForm = () => {
  return (
    <div className='review_landing_page'>
        <ReviewPage/>
    </div>
  )
}

export default ReviewForm
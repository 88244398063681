import React, { useRef, useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import FileField from "./elements/file"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import { getUser } from "@starberry/myaccount-website-utils"
import $ from "jquery/dist/jquery.min.js"

import "../../scss/forms.scss"

import axios from "axios"
import * as qs from "query-string"

function ReviewForm(props) {
  const userData = getUser()
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")
  const [file, setFile] = useState("") // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" })
  const [progress, setProgess] = useState(0) // progess bar
  const el = useRef() // accesing input element

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()
  const fields = [
    {
      element: "config",
      formname: "Review",
      error_text: "Highlighted fields are required | invalid",
      success_text:
        "Thank you for your review.",
      email_temp_user: "review_form_user",
      email_temp_admin: "review_form_admin",
      email_subject_user: "Review Us",
      email_subject_admin: "Review Us",
      email_server_func: "review",
      event_tracking: "review",
      page_url: "/review",
    },
    {
      grpmd: "6",
      label: "First Name",
      placeholder: "Your First Name *",
      name: "first_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      defaultValue: userData?.name || ``,
    },
    {
      grpmd: "6",
      label: "Last Name",
      placeholder: "Your Last Name *",
      name: "last_name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      fieldClass: "",
      labelClass: "content_b-18",
      defaultValue: userData?.surname || ``,
    },
    {
      grpmd: "6",
      label: "Email Address",
      placeholder: "Your Email Address *",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      class: "",
      defaultValue: userData?.email || ``,
    },
    {
      grpmd: "6",
      label: "Phone Number",
      placeholder: "Your Phone Number *",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      fieldClass: "",
      labelClass: "content_b-18",
      defaultValue: userData?.mobile || ``,
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write your message here...",
      name: "message",
      element: "textarea",
      class: "mb-4 msg-area ",
      rows: "3",
      required: false,
      labelClass: "content_b-18",
    },
    {
      step: "col-md-12",
      text:
        'By clicking Submit, you agree to our <a href="/terms-of-use/"> Terms &amp; Conditions </a> and <a href="/privacy-policy/"> Privacy Policy </a>',
      element: "html",
      class: "form-block-text link",
    },
    {
      name: "Submit Review",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn btn-primary",
      formclass: "",
    },

    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0] // accesing file

    var d = document.getElementById("custom-file")
    //alert(d.value);
    $(".form-control-browsefile .custom-file-label").html(
      d.value.replace(/C:\\fakepath\\/, "")
    )

    setFile(fileItem) // storing file
  }

  useEffect(() => {
    if (token !== "") {
      // debugger
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token

        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          first_name: formvalues.first_name,
          last_name: formvalues.last_name,
          interest: formvalues.interest,
        })
        formvalues["name"] = formvalues.first_name + " " + formvalues.last_name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin
        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        })

        const url = typeof window !== "undefined" ? window.location.href : ""
        if (props.title != "") {
          fields[0].formname = props.title
        }
        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: fields[0].formname,
        })

        setShowerror(false)
        setThankyou(true)
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut()
        }, 500)
        myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    // debugger
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token

      json["files"] = file
      setFile(file)
      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <div>
      <div ref={myRef} />

      {showerror && (
        <div className="alert alert-danger alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>
      )}

      <Form
        className="form"
        id="contact-form"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="bot-field" />
        <div className="row">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              )
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                />
              )
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  id={field.id}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              )
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              )
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              )
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  step={field.step}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              )
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              )
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              )
            }
          })}
        </div>
      </Form>
    </div>
  )
}

const ReviewFormPage = props => <ReviewForm />

export default ReviewFormPage
